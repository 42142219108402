let loaderHidden = false;

function hideLoader() {
	if (loaderHidden) return;
	loaderHidden = true;
	// Set relative (%) height to absolute one to avoid rescaling
	const header = $('#header');
	header.height(header.height());
	$('.loader').fadeOut();
	$('.page-loader').delay(350).fadeOut('slow');
}

// Show the content after it has fully loaded.
$(window).load(() => hideLoader());
// Show the content anyway if it hasn't loaded after 3 seconds
window.setTimeout(hideLoader, 3000); 

