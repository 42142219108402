$(document).ready(() => {
	const editables = $("[data-ks-edit-url]");
	editables.each(function () {
		const $element = $(this);
		$element[0].style.position = 'relative';
		const url = $element.attr("data-ks-edit-url");
		const link = $(`<a class="ks-editable-btn" href="${url}" target="_blank">Edit</a>`);
		$element.append(link);
		$element.hover(() => link[0].style.opacity = '1',() => link[0].style.opacity = '0');
	});
});
