import 'dotdotdot/src/js/jquery.dotdotdot';

$(document).ready(function () {
	const ellipseElements = $('.dotdotdot');
	ellipseElements.each(function () {
		const $element = $(this);
		const options = {
			watch: true
		};
		
		if($element.attr("data-height")){
			options.height = parseInt($element.attr("data-height"));
		}
		if($element.attr("data-after")){
			options.after = $element.attr("data-after");
		}
		$element.dotdotdot(options);
	});
});

