
$(document).ready(() => {
	const navbar = $('.navbar.navbar-transparent'),
		navHeight = navbar.height();
	let menuOpen = false;

	navbarAnimation(navbar, navHeight, menuOpen);

	$(window).scroll(() => navbarAnimation(navbar, navHeight, menuOpen));
	
	navbar.on('show.bs.collapse', () => {
		menuOpen = true;
		navbarAnimation(navbar, navHeight, menuOpen);
	});
	navbar.on('hide.bs.collapse', () => {
		menuOpen = false;
		navbarAnimation(navbar, navHeight, menuOpen);
	});
});

/* ---------------------------------------------- /*
 * Transparent navbar animation
 /* ---------------------------------------------- */
function navbarAnimation(navbar, navHeight, menuOpen) {
	const topScroll = $(window).scrollTop();
	if (navbar.length > 0) {
		if (menuOpen || topScroll >= navHeight) {
			navbar.removeClass('navbar-transparent');
		} else {
			navbar.addClass('navbar-transparent');
		}
	}
}
